module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Neufchateau 360 Experience","short_name":"Neufchateau 360 Experience","start_url":"/","background_color":"#fff","theme_color":"#7D5E4F","icon":"src/images/icon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"39617d02fd52cb85f7f983e19762b824"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr"],"defaultLanguage":"fr","generateDefaultLanguagePage":true,"siteUrl":"https://360.neufchateau.be","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"returnObjects":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","anonymize":true},"facebookPixel":{"pixelId":""}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
